import request from "@/utils/request";

//获取所有主页商品
export const getAll = (params) => {
  return request({
    url: "/portal/app/shop/category/all",
    method: "get",
    params,
  });
};

//获取所有的产品分类信息，顶级分类的parentId为0,默认为顶级分类
export const getParent = (params) => {
  return request({
    url: "/portal/app/shop/category/byParent",
    method: "get",
    params,
  });
};

//获取商品详情信息
export const getDetail = (params) => {
  return request({
    url: "/portal/app/shop/prod/prodInfo",
    method: "get",
    params,
  });
};

//根据id获取信息
export const getShoplist = (params) => {
  return request({
    url: "/portal/app/shop/prod",
    method: "get",
    params,
  });
};

//发送验证码
export const getCode = (data = null) => {
  return request({
    url: "/common/sms",
    method: "post",
    data,
  });
};

//注册
export const getRegister = (data = null) => {
  return request({
    url: "/portal/register",
    method: "post",
    data,
  });
};

//登录
export const getLogin = (data = null) => {
  return request({
    url: "/portal/login",
    method: "post",
    data,
  });
};

//修改密码
export const changepwd = (data = null) => {
  return request({
    url: "/portal/password",
    method: "put",
    data,
  });
};

//忘记密码
export const forgetpwd = (data = null) => {
  return request({
    url: "/portal/forget",
    method: "put",
    data,
  });
};

//结算，生成订单信息
export const createOrder = (data = null) => {
  return request({
    url: "/wxapp/app/shop/p/order/confirm",
    method: "post",
    data,
  });
};

//提交订单，返回支付流水号
export const submitOrder = (data = null) => {
  return request({
    url: "/wxapp/app/shop/p/order/submit",
    method: "post",
    data,
  });
};

//根据订单号进行支付（微信）
export const payOrder = (data = null) => {
  return request({
    url: "/portal/app/shop/p/order/pay",
    method: "post",
    data,
  });
};

//根据订单号进行支付（阿里）
export const payOrderAli = (data = null) => {
  return request({
    url: "/portal/app/shop/p/order/alipay",
    method: "post",
    data,
  });
};

// 查看订单支付状态。2秒一次
export const viewState = (params) => {
  return request({
    url: "/wxapp/app/shop/p/myOrder/isPayed",
    method: "get",
    params,
  });
};

// 订单列表信息
export const getMyorder = (params) => {
  return request({
    url: "/wxapp/app/shop/p/myOrder/myOrder",
    method: "get",
    params,
  });
};

// 获取订单数量
export const getMyorderCount = (params) => {
  return request({
    url: "/wxapp/app/shop/p/myOrder/orderCount",
    method: "get",
    params,
  });
};

// 订单详情信息
export const getorderDetail = (params) => {
  return request({
    url: "/wxapp/app/shop/p/myOrder/orderDetail",
    method: "get",
    params,
  });
};

// 微信小程序跳转
export const getWxurl = (params) => {
  return request({
    url: "/wxapp/urlLink",
    method: "get",
    params,
  });
};

//微信扫码登录
export const getQrcode = (params) => {
  return request({
    url: "/portal/qrcode",
    method: "get",
    params,
  });
};

//检查登录状态，每两秒一次，成功后会返回登录结果
export const checkWx = (data = null) => {
  return request({
    url: "/portal/checkLogin",
    method: "post",
    data,
  });
};

//微信小程序绑定手机号
export function bandWxphone(data = null) {
  return request({
    url: "/portal/bindPhone",
    method: "post",
    data,
  });
}

//微信小程序跳转
export const changeWxurl = (params) => {
  return request({
    url: "/wxapp/config/originId",
    method: "get",
    params,
  });
};

// 获取埋点 应用appid
export const getApplyAppId = () => {
  return request({
    url: "/wxapp/app/agency/data-finder/config",
    method: "get",
  });
};

// 获取优惠券列表
export const getCouponList = () => {
  return request({
    url: "/wxapp/app/shop/coupon/all",
    method: "get",
  });
};

//总价计算
export function total(data = null) {
  return request({
    url: "/wxapp/app/shop/p/order/calcPrice",
    method: "post",
    data,
  });
}

export function deleteOrder(data = null) {
  return request({
    url: `/wxapp/app/shop/p/myOrder/cancel/${data}`,
    method: "put",
    data,
  });
}
